/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {

      init: function() {


        var isInIframe = (window.location !== window.parent.location) ? true : false;
        if(!isInIframe){

          if($(".home").length>0 || $(".konfigurator-und-bestellung").length>0 || $(".konfigurator").length>0 || $(".warenkorb").length>0 || $(".abschluss").length>0 ){
             console.log(window.location.href);
             console.log(window.location.pathname);
             console.log(window.location.search);
          }else{
         if($(".woocommerce-order-received").length>0){
           var pName2=window.location.pathname;
           pName2=pName2.replace("/","");
         //  $.router.add(window.location.pathname, function(data) {

               var newURL2 = window.location.protocol + "//" + window.location.host + "/#" + pName2 + window.location.search ;
           window.location.href=newURL2;
         }else{
        $("body").hide();
        console.log(window.location.pathname);
        var pName=window.location.pathname;
        pName=pName.replace("/","");
      //  $.router.add(window.location.pathname, function(data) {

            var newURL = window.location.protocol + "//" + window.location.host + "/#" + pName;
        window.location.href=newURL;
        ///});
      }
      }
      }

		var $mmenu = $('#main_navi_mobile'),$html = $('html, body');

		$mmenu.mmenu({

			extensions: ["theme-white", "pagedim-black", "effect-slide-menu", "effect-listitems-slide","border-full"],
			offCanvas: {
				moveBackground: false,
				position: "right",
				zposition : "front"
			},
			navbar: {
				add: false
			},
			navbars: [
				{
						position: "top",
						content: [ '' ]
					}
			],
			slidingSubmenus: false
	}, {

			offCanvas: {
				pageSelector: "> div:not(#wpadminbar)"
			},
			clone:true
		}).removeClass('invisible');

		var API = $('#mm-main_navi_mobile').data( "mmenu" );

	$('#mm-main_navi_mobile').find( 'li > a' ).on('click',function(event){
					        var hlink = $(this).attr( 'href' );


				             API.bind( "closed", function() {

							setTimeout(function(){

							$.scrollTo(hlink, 650, { easing: 'easeInOutExpo' , offset: -140 , 'axis':'y' },10);
							hlink=null;

						    });
							});

	});

/*  $("#main_navi a").each(function(index1){
      $(this).click(function(e){
      //return false;
      });
  });*/



  $("#main_navi li").each(function(index){
    $(this).click(function(e){
     e.preventDefault();
    	$.scrollTo($(this).find("a").attr("href"), 850, { easing: 'easeInOutExpo' , offset: -140 , 'axis':'y' },10);
    });
  });

  try{
    $(".cta-scroll a").click(function(e){
      e.preventDefault();
    	$.scrollTo($(this).attr("href"), 850, { easing: 'easeInOutExpo' , offset: -140 , 'axis':'y' },10);
    });
}catch(e){
  $.noop();
}

try{
  $("#startseite_anker").click(function(e){
    e.preventDefault();
    $.scrollTo("#startseite", 850, { easing: 'easeInOutExpo' , offset: -140 , 'axis':'y' },10);
  });
}catch(e){
$.noop();
}



try{
  $(".nav_btn a").click(function(e){
    e.preventDefault();

  });
}catch(e){
$.noop();
}



/*
$("section").each(function(index){



$(this).on('inview', function(event, isInView) {
 if (isInView) {
   // element is now visible in the viewport
   console.log($(this).attr("id")+ "inview");
 } else {
   // element has gone out of viewport
   console.log($(this).attr("id")+ "outview");

 }
});

});*/




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        try{
           if($(".zoom_image").length>0){
             $(".zoom_image").each(function(){
               $(this).find(".zoom").magnify();
             });
           }
         }catch(err){
           $.noop();
         }

         try{

         $(document).on('scroll', function () {
             // if the scroll distance is greater than 100px

             if ($(window).scrollTop() > 50) {
               // do something
               $('header.banner').addClass("scrolled");
               $('.main').addClass("scrolled");
             }else{
         		 $('header.banner').removeClass("scrolled");
             $('.main').removeClass("scrolled");
         	   }
         });


         		}catch(e){
         		  $.noop();
         		}



      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      console.log(window.location.hash.substr(1));
      hashpath=window.location.hash.substr(1);
     switch(window.location.hash.substr(1)){
       case "impressum/":
        $.featherlight("/impressum/ .main",{type: 'ajax'});
       break;
       case "agb/":
        $.featherlight("/agb/ .main",{type: 'ajax'});
       break;
       case "datenschutz-cookies/":
        $.featherlight("/datenschutz-cookies/ .main",{type: 'ajax'});
       break;
       default:
       if(hashpath.indexOf("produkt")>-1){
           $.featherlight("/"+hashpath+" #container",{type: 'ajax'});
       }
       if(hashpath.indexOf("order-received")>-1){
           $.featherlight("/"+hashpath,{type: 'iframe'});
       }
     }

  $('#inhalte-des-basispakets .products .product a:not(.XXX)').each(function(inx){

        $(this).attr("href",$(this).attr("href")+ " #container");

   });
     $('#inhalte-des-basispakets .products a').featherlight({type: 'ajax'});
       $(".hero-image").attr("data-bg","white");
        $(".counter-teasers").attr("data-bg","blue");



       var $head = $( '.banner' );
       $( '[data-bg]' ).each( function(i) {
       	var $el = $( this );

       	$el.waypoint( function( direction ) {
       		if( direction === 'down') {
             if($( this ).data("bg")==="blue"){
               $(".logo-blue").show();
               $(".logo-white").hide();
       			}else{
               $(".logo-blue").hide();
               $(".logo-white").show();
             }
       		}
       		else if( direction === 'up'){
           if($( this ).data("bg")==="blue"){
               $(".logo-blue").show();
               $(".logo-white").hide();
       			}else{
               $(".logo-blue").hide();
               $(".logo-white").show();
             }
       		}
       	}, { offset:  '100px'  } );
       } );


   try{
       $(document).on('scroll', function () {
    // if the scroll distance is greater than 100px
    if ($(window).scrollTop() > 150) {
      // do something
      $("#startseite_anker").fadeIn(500);

    }else{
		  $("#startseite_anker").fadeOut(500);

    }
     });

     if ($(window).scrollTop() > 150) {
       // do something
       $("#startseite_anker").fadeIn(500);

     }else{
 		  $("#startseite_anker").fadeOut(500);

     }


   }catch(err){
     $.noop();
   }


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        //$('.logo .brand').midnight();



      }
    },
    // About us page, note the change from about-us to about_us.
    'konfigurator': {
      init: function() {
        // JavaScript to be fired on the about us page
        $("div[itemprop='offers']").remove();
        $(".shop_table thead").remove();
        $(".product-remove").remove();
        $(".step-btn-1").addClass("active");
        $(".step-btn-2").removeClass("active");
        $(".step-btn-3").removeClass("active");
        $(".step-btn-4").removeClass("active");
        $("body").addClass("step-1-page");
        $("body").removeClass("step-3-page");
        $("input[name='mnm_quantity[633]']").closest(".pro-wrapper").hide();
        $(".input-text.qty.text").val(0);
         $(".mnm_cart.cart").find(".input-text.qty.text").val(1);
          $(".input-text.qty.text").bind('click keyup change input',function(){
             $("#sum").text($(".mnm_cart.cart").find(".amount").text());
             var pr_item= $(this).closest(".pro-wrapper").data("regular_price");
             var pr_item_sum=pr_item*$(this).val();
             console.log(pr_item_sum);
             $(this).closest(".pro-wrapper").find(".status-check").find(".price").text("+"+pr_item_sum+" €");
             $(this).closest(".pro-wrapper").find(".checked").addClass("active");
             $(this).closest(".pro-wrapper").addClass("ordered");


            if($(this).val()==="0"){
              $(this).closest(".pro-wrapper").find(".status-check").find(".price").text("");
               $(this).closest(".pro-wrapper").find(".checked").removeClass("active");
                $(this).closest(".pro-wrapper").removeClass("ordered");
            }
          });

           $("input[name='mnm_quantity[633]']").val(1);
          $(".btn_next.btn-next1").click(function(){
          //  window.location.href="/warenkorb";
           $(".single_add_to_cart_button").trigger("click");
          });


          $("#sum").text($("input[name='mnm_quantity[633]']").closest(".pro-wrapper").data("regular_price")+ " €");

          $(".product-name a").each(function(index){
            var pname2=$(this);
            var pname_url=$(this).attr("href");
            $(this).attr("data-featherlight","ajax");
            $(this).attr("href","/produkt/"+pname_url+" #container");
         pname2.append('<a rel="nofollow" data-featherlight="ajax" href="/produkt/'+pname_url+' #container" class="produkt_info">i</a>');
       });
    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS
      //$('.logo .brand').midnight();





    }
    },

    'konfigurator_mini': {
      init: function() {
      
        // JavaScript to be fired on the about us page
        $("div[itemprop='offers']").remove();
        $(".shop_table thead").remove();
        $(".product-remove").remove();
        $(".step-btn-1").addClass("active");
        $(".step-btn-2").removeClass("active");
        $(".step-btn-3").removeClass("active");
        $(".step-btn-4").removeClass("active");
        $("body").addClass("step-1-page");
        $("body").removeClass("step-3-page");
        $("input[name='mnm_quantity[724]']").closest(".pro-wrapper").hide();
        $(".input-text.qty.text").val(0);
         $(".mnm_cart.cart").find(".input-text.qty.text").val(1);
          $(".input-text.qty.text").bind('click keyup change input',function(){
             $("#sum").text($(".mnm_cart.cart").find(".amount").text());
             var pr_item= $(this).closest(".pro-wrapper").data("regular_price");
             var pr_item_sum=pr_item*$(this).val();
             console.log(pr_item_sum);
             $(this).closest(".pro-wrapper").find(".status-check").find(".price").text("+"+pr_item_sum+" €");
             $(this).closest(".pro-wrapper").find(".checked").addClass("active");
             $(this).closest(".pro-wrapper").addClass("ordered");


            if($(this).val()==="0"){
              $(this).closest(".pro-wrapper").find(".status-check").find(".price").text("");
               $(this).closest(".pro-wrapper").find(".checked").removeClass("active");
                $(this).closest(".pro-wrapper").removeClass("ordered");
            }
          });

           $("input[name='mnm_quantity[724]']").val(1);
          $(".btn_next.btn-next1").click(function(){
          //  window.location.href="/warenkorb";
           $(".single_add_to_cart_button").trigger("click");
          });


          $("#sum").text($("input[name='mnm_quantity[724]']").closest(".pro-wrapper").data("regular_price")+ " €");

          $(".product-name a").each(function(index){
            var pname2=$(this);
            var pname_url=$(this).attr("href");
            $(this).attr("data-featherlight","ajax");
            $(this).attr("href","/produkt/"+pname_url+" #container");
         pname2.append('<a rel="nofollow" data-featherlight="ajax" href="/produkt/'+pname_url+' #container" class="produkt_info">i</a>');
       });

       $("input[name='mnm_quantity[416]']").attr("max",1);
       $("input[name='mnm_quantity[416]']").change(function() {
      var max = parseInt($(this).attr('max'));
      var min = parseInt($(this).attr('min'));
      if ($(this).val() > max)
      {
          $(this).val(max);
      }
      else if ($(this).val() < min)
      {
          $(this).val(min);
      }
    });


    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS
      //$('.logo .brand').midnight();





    }
    },
    'konfigurator_auswahl': {
              init: function() {

              $(".konfigurator-auswahl .woocommerce a").click(function(){
                $(this).parents(".woocommerce").addClass("selectedCat");
              });

            },
            finalize: function() {
              // JavaScript to be fired on the home page, after the init JS
              //$('.logo .brand').midnight();





            }
      },


    'warenkorb': {
      init: function() {

             $("#sum").html($(".woocommerce-Price-amount.amount").html());
            $(".btn_next.btn-next1").click(function(){
              console.log("click");
             window.location.href="/checkout";
            });

            $(".product-name a").each(function(index){
              var pname2=$(this);
              var pname_url=$(this).attr("href");
              $(this).attr("data-featherlight","ajax");
              $(this).attr("href","/produkt/"+pname_url+" #container");
              var pname_title=$(this).text();
              $(this).replaceWith("<p>"+pname_title+"</p>").append('<a rel="nofollow" data-featherlight="ajax" href="/produkt/'+pname_url+' #container" class="produkt_info">i</a>');

           pname2.parent().append('<a rel="nofollow" data-featherlight="ajax" href="/produkt/'+pname_url+' #container" class="produkt_info">i</a>');
         });

    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS
      //$('.logo .brand').midnight();





    }
    },


        'checkout': {
          init: function() {

                 $("#sum").html($(".woocommerce-Price-amount.amount").html());
                $(".btn_next.btn-next1").click(function(){
                  console.log("click");
                // window.location.href="/checkout";
                  $("#place_order").trigger("click");
                });

                if($(".woocommerce-order-received").length>0){
                  $(".btn_next.btn-next1").hide();
                  $(".btn_back.btn-back-2").find("a").attr("href","/konfigurator");
                  $(".step-btn-3").removeClass("active");
                  $(".step-btn-4").addClass("active");
                }

                $("p.legal.terms a").attr("data-featherlight","ajax");
                $("p.legal.terms a").attr("href",$(".legal.terms a").attr("href")+" .main");

        },
        finalize: function() {
          // JavaScript to be fired on the home page, after the init JS
          //$('.logo .brand').midnight();





        }
        },

    'konfigurator_und_bestellung': {
     init: function() {

       var price1=0;
       var price2=0;
       var price3=0;
       var price4=0;
       var price5=0;
       var price6=0;
       var price7=0;
       var price8=0;
       var price9=0;
       var price10=0;
       var sumAllP=0;
       $("#input_3_29").val(349);
       $(".pro-quantity-1").val(0);
       $("#input_3_1").val("");
       $(".price-1").html("");
       $(".pro-quantity-2").val(0);
       $("#input_3_2").val("");
       $(".price-2").html("");
       $(".pro-quantity-3").val(0);
       $("#input_3_3").val("");
       $(".price-3").html("");
       $(".pro-quantity-4").val(0);
       $("#input_3_4").val("");
       $(".price-4").html("");
       $(".pro-quantity-5").val(0);
       $("#input_3_5").val("");
       $(".price-5").html("");
       $(".pro-quantity-6").val(0);
       $("#input_3_6").val("");
       $(".price-6").html("");
       $(".pro-quantity-7").val(0);
       $("#input_3_7").val("");
       $(".price-7").html("");
       $(".pro-quantity-8").val(0);
       $("#input_36").val("");
       $(".price-8").html("");
       $(".pro-quantity-9").val(0);
       $("#input_34").val("");
       $(".price-9").html("");
       $(".pro-quantity-10").val(0);
       $("#input_35").val("");
       $(".price-10").html("");
       $(".checked-1").removeClass("active");
       $(".toogle-1").removeClass("active");
       $(".checked-2").removeClass("active");
       $(".toogle-2").removeClass("active");
       $(".checked-3").removeClass("active");
       $(".toogle-3").removeClass("active");
       $(".checked-4").removeClass("active");
       $(".toogle-4").removeClass("active");
       $(".checked-5").removeClass("active");
       $(".toogle-5").removeClass("active");
       $(".checked-6").removeClass("active");
       $(".toogle-6").removeClass("active");
       $(".checked-7").removeClass("active");
       $(".toogle-7").removeClass("active");
       $(".toogle-8").removeClass("active");
       $(".toogle-9").removeClass("active");
       $(".toogle-10").removeClass("active");
       $(".checked-8").removeClass("active");
       $(".checked-9").removeClass("active");
       $(".checked-10").removeClass("active");
       $(".toogle-1").prop('checked', false);
       $(".toogle-2").prop('checked', false);
       $(".toogle-3").prop('checked', false);
       $(".toogle-4").prop('checked', false);
       $(".toogle-5").prop('checked', false);
       $(".toogle-6").prop('checked', false);
       $(".toogle-7").prop('checked', false);
       $(".toogle-8").prop('checked', false);
       $(".toogle-9").prop('checked', false);
       $(".toogle-10").prop('checked', false);

       $("#sum").html($("#input_3_29").val()+" €");

       $("body").addClass("step-1-page");
       $("body").removeClass("step-3-page");

       $(".step-btn-1").addClass("active");
       $(".step-btn-2").removeClass("active");
       $(".step-btn-3").removeClass("active");
       $(".step-btn-4").removeClass("active");

     $(".pro-wrapper:not(.no-info)").each(function(index){
       var pname=$(this).find("p").first();
       if(pname.height()>28){
         pname.addClass("bigger");
       }
       pname_text=pname.text();
      // console.log(pname_text);
       switch(pname_text){
         case "Steuerungseinheit":
         pname.append('<a rel="nofollow" data-featherlight="ajax" href="/produkt/kalavantin-steuerungseinheit #container" class="produkt_info">i</a>');
         break;
         case "Tür- oder Fensterkontakt":
         pname.append('<a rel="nofollow" data-featherlight="ajax" href="/produkt/tuer-fensterkontakt #container" class="produkt_info">i</a>');
         break;
         case "Tür-/Fensterkontakt":
         pname.append('<a rel="nofollow" data-featherlight="ajax" href="/produkt/tuer-fensterkontakt #container" class="produkt_info">i</a>');
         break;
         case "Fensterkontakt":
         pname.append('<a rel="nofollow" data-featherlight="ajax" href="/produkt/tuer-fensterkontakt #container" class="produkt_info">i</a>');
         break;
         case "Steuerungs-APP":
         pname.append('<a rel="nofollow" data-featherlight="ajax" href="/produkt/kalavantin-app #container" class="produkt_info">i</a>');
         break;
         case "Überfallknopf":
         pname.append('<a rel="nofollow" data-featherlight="ajax" href="/produkt/ueberfallknopf #container" class="produkt_info">i</a>');
         break;
         case "Elektronischer Schlüssel-Transponder":
         pname.append('<a rel="nofollow" data-featherlight="ajax" href="/produkt/rfid-transponder #container" class="produkt_info">i</a>');
         break;
         case "Fachmännische Vor-Ort Installation (deutschlandweit)":
         break;
         case "Aufschaltung zur Sicherheitszentrale":
         break;
         default:
         var plink = pname.text().replace(/\s+/ig, '-').replace(/\//ig, '').replace(/ä/ig,"ae").replace(/ö/ig,"oe").replace(/ü/ig,"ue").replace(/ß/ig,"ss").toLowerCase();

         pname.append('<a rel="nofollow" data-featherlight="ajax" href="/produkt/'+plink+' #container" class="produkt_info">i</a>');
       }


     });


       function format1(n, currency) {
     return currency + " " + n.toFixed(2).replace(/./g, function(c, i, a) {
         return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
     });
      }

      function sumAll(){

        sumAllP=Number($("#input_3_29").val()) + price1 + price2 + price3 + price4 + price5 + price6 + price7 + price8 + price9 + price10;



        $("#sum").html(format1(sumAllP,'')+ "€");
      }



      function final_check_populate(){

        var fc_name="";
        var fc_billing="";
        var fc_shipping="";
        var fc_phone="";
        var fc_mail="";
        var fc_bank="";

        fc_name=fc_name+$("#input_3_9").val()+" ";
        $("p.name").html(fc_name);
        fc_name=fc_name+$("#input_3_10").val()+" ";
        $("p.name").html(fc_name);
        fc_name=fc_name+$("#input_3_30").val()+" ";
        $("p.name").html(fc_name);



        fc_billing=fc_billing+$("#input_3_11").val()+"<br> ";
        $("p.billing_adr").html(fc_billing);

        fc_billing=fc_billing+$("#input_3_12").val()+" ";
        $("p.billing_adr").html(fc_billing);


        fc_billing=fc_billing+$("#input_3_13").val()+" ";
        $("p.billing_adr").html(fc_billing);

        fc_shipping=fc_shipping+$("#input_3_24").val()+"<br> ";
        $("p.shipping_adr").html(fc_shipping);
        fc_shipping=fc_shipping+$("#input_3_25").val()+" ";
        $("p.shipping_adr").html(fc_shipping);
        fc_shipping=fc_shipping+$("#input_3_26").val()+" ";


        $("p.shipping_adr").html(fc_shipping);
        if(fc_shipping===""){
          $("p.shipping_adr").html("Lieferadresse identisch mit Rechnungsadresse.");
        }


        fc_phone=fc_phone+"Mobilnummer: "+$("#input_3_16").val()+"<br> ";
        $("p.phone").html(fc_phone);



        fc_phone=fc_phone+"Festnetznummer: "+$("#input_3_17").val()+" ";
        $("p.phone").html(fc_phone);



        fc_mail=fc_mail+$("#input_3_37").val()+" ";
        $("p.mail").html(fc_mail);
        $(".mail_text").html(fc_mail);



        fc_bank=fc_bank+"IBAN: "+$("#input_3_20").val()+"<br> ";
        $("p.bank").html(fc_bank);



        fc_bank=fc_bank+"BIC: "+ $("#input_3_21").val()+" <br>";
        $("p.bank").html(fc_bank);



        fc_bank=fc_bank+$("#input_3_22").val()+" ";
        $("p.bank").html(fc_bank);

        $("#input_3_29").val((format1(sumAllP,'')+ "€"));

    }


    function ordered(product){
      if(product.val()>0){
        product.parent().parent().parent().parent().parent().parent().addClass("ordered");
      }else{
        product.parent().parent().parent().parent().parent().parent().removeClass("ordered");
      }
    }

    function validate_data(){

      $("li:not(.add) input.large").each(function(index){
        if ($(this).val() === '') {
            $(this).addClass("error");
        }else{
          $(this).removeClass("error");
        }
      });
     if($("#choice_3_23_1").is(":checked")){
       $("#choice_3_23_1").parent().removeClass("error");
     }else{
        $("#choice_3_23_1").parent().addClass("error");
     }
     if($("#choice_3_23_2").is(":checked")){
       $("#choice_3_23_2").parent().removeClass("error");
     }else{
        $("#choice_3_23_2").parent().addClass("error");
     }

     if($(".error").length>0){
       return false;
     }else{
       return true;
     }

    }



        $(".pro-quantity-1").bind('click keyup change input',function(){
          if($(this).val()>0){

           ordered($(this));
           $(".checked-1").addClass("active");

           $(".toogle-1").addClass("active");

         }else{
           $(".checked-1").trigger("click");

         }

           price1= $(this).attr("data-price")*$(this).val();
           price1f=format1(price1,'');
          $("#input_3_1").val( $(this).val()+"x "+ price1f +"€");
          $(".price-1").html("+"+price1f+"€");
          sumAll();
         if($(this).val()==="0"){
           $(".price-1").html("");
         }

        });

        $(".pro-quantity-2").bind('click keyup',function(){
            if($(this).val()>0){
             ordered($(this));
           $(".checked-2").addClass("active");
           $(".toogle-2").addClass("active");

         }else{
           $(".checked-2").trigger("click");

         }

           price2= $(this).attr("data-price")*$(this).val();
           price2f=format1(price2,'');
          $("#input_3_2").val( $(this).val()+"x "+ price2f +"€");
          $(".price-2").html("+"+price2f+"€");
           sumAll();

           if($(this).val()==="0"){
             $(".price-2").html("");
           }

        });

        $(".pro-quantity-3").bind('click keyup',function(){
           if($(this).val()>0){
            ordered($(this));
           $(".checked-3").addClass("active");
           $(".toogle-3").addClass("active");
         }else{
           $(".checked-3").trigger("click");

         }

           price3= $(this).attr("data-price")*$(this).val();
           price3f=format1(price3,'');
          $("#input_3_3").val( $(this).val()+"x "+ price3f +"€");
          $(".price-3").html("+"+price3f+"€");
          sumAll();

          if($(this).val()==="0"){
            $(".price-3").html("");
          }


        });

        $(".pro-quantity-4").bind('click keyup',function(){
            if($(this).val()>0){
             ordered($(this));
           $(".checked-4").addClass("active");
           $(".toogle-4").addClass("active");
         }else{
           $(".checked-4").trigger("click");

         }


           price4= $(this).attr("data-price")*$(this).val();
           price4f=format1(price4,'');
          $("#input_3_4").val( $(this).val()+"x"+ price4f +"€");
          $(".price-4").html("+"+price4f+"€");
           sumAll();

           if($(this).val()==="0"){
             $(".price-4").html("");
           }

        });

        $(".pro-quantity-5").bind('click keyup',function(){
             if($(this).val()>0){
            ordered($(this));
           $(".checked-5").addClass("active");
           $(".toogle-5").addClass("active");

         }else{
           $(".checked-5").trigger("click");

         }

           price5= $(this).attr("data-price")*$(this).val();
           price5f=format1(price5,'');
          $("#input_3_5").val( $(this).val()+"x "+ price5f +"€");
          $(".price-5").html("+"+price5f+"€");
           sumAll();

           if($(this).val()==="0"){
             $(".price-5").html("");
           }


         });

        $(".pro-quantity-6").bind('click keyup',function(){
            if($(this).val()>0){
            ordered($(this));
           $(".checked-6").addClass("active");
           $(".toogle-6").addClass("active");


         }else{
           $(".checked-6").trigger("click");

         }

           price6= $(this).attr("data-price")*$(this).val();
           price6f=format1(price6,'');
          $("#input_3_6").val( $(this).val()+"x "+ price6f +"€");
          $(".price-6").html("+"+price6f+"€");

           sumAll();

           if($(this).val()==="0"){
             $(".price-6").html("");
           }


        });

        $(".pro-quantity-7").bind('click keyup',function(){
           if($(this).val()>0){
           ordered($(this));
           $(".checked-7").addClass("active");
           $(".toogle-7").addClass("active");

         }else{
           $(".checked-7").trigger("click");

         }


           price7= $(this).attr("data-price")*$(this).val();
           price7f=format1(price7,'');
          $("#input_3_7").val( $(this).val()+"x "+ price7f +"€");
          $(".price-7").html("+"+price7f+"€");

           sumAll();

           if($(this).val()==="0"){
             $(".price-7").html("");
           }



        });

        $(".pro-quantity-8").bind('click keyup',function(){
          if($(this).val()>0){
           ordered($(this));
           $(".checked-8").addClass("active");
           $(".toogle-8").addClass("active");

         }else{
           $(".checked-8").trigger("click");

         }



           price8= $(this).attr("data-price")*$(this).val();
           price8f=format1(price8,'');
          $("#input_3_36").val( $(this).val()+"x "+ price8f +"€");
          $(".price-8").html("+"+price8f+"€");

           sumAll();

           if($(this).val()==="0"){
             $(".price-8").html("");
           }


        });


        $(".pro-quantity-9").bind('click keyup',function(){
           if($(this).val()>0){
           ordered($(this));
           $(".checked-9").addClass("active");
           $(".toogle-9").addClass("active");
         }else{
           $(".checked-9").trigger("click");

         }

           price9= $(this).attr("data-price")*$(this).val();
           price9f=format1(price9,'');
          $("#input_3_34").val( $(this).val()+"x "+ price9f +"€");
          $(".price-9").html("+"+price9f+"€");

           sumAll();

           if($(this).val()==="0"){
             $(".price-9").html("");
           }


        });

        $(".pro-quantity-10").bind('click keyup',function(){
           if($(this).val()>0){
           ordered($(this));
           $(".checked-10").addClass("active");
           $(".toogle-10").addClass("active");

         }else{
           $(".checked-10").trigger("click");

         }

           price10= $(this).attr("data-price")*$(this).val();
           price10f=format1(price10,'');
          $("#input_3_35").val( $(this).val()+"x "+ price10f +"€");
          $(".price-10").html("+"+price10f+"€");

           sumAll();

           if($(this).val()==="0"){
             $(".price-10").html("");
           }




        });

        $(".toogle-1").click(function(){
           if($(this).is(':checked')){
             $(this).addClass("active");
             $(".checked-1").addClass("active");
               $(".pro-quantity-1").val(1);
             $(".pro-quantity-1").trigger("click");
           }
        });

        $(".checked-1").click(function(){
            $(this).removeClass("active");
            $(".toogle-1").removeClass("active");
            $(".toogle-1").prop('checked', false);
            $(".pro-quantity-1").val(0);
            ordered($(".pro-quantity-1"));
            $("#input_3_1").val("");
            $(".price-1").html("");
            price1=0;
               sumAll();
        });

        $(".toogle-2").click(function(){
           if($(this).is(':checked')){
             $(this).addClass("active");
             $(".checked-2").addClass("active");
               $(".pro-quantity-2").val(1);
             $(".pro-quantity-2").trigger("click");
           }
        });

        $(".checked-2").click(function(){
            $(this).removeClass("active");
            $(".toogle-2").removeClass("active");
            $(".toogle-2").prop('checked', false);
            $(".pro-quantity-2").val(0);
            ordered($(".pro-quantity-2"));
            $("#input_3_2").val("");
            $(".price-2").html("");
            price2=0;
               sumAll();
        });

        $(".toogle-3").click(function(){
           if($(this).is(':checked')){
             $(this).addClass("active");
             $(".checked-3").addClass("active");
               $(".pro-quantity-3").val(1);
             $(".pro-quantity-3").trigger("click");
           }
        });

        $(".checked-3").click(function(){
            $(this).removeClass("active");
            $(".toogle-3").removeClass("active");
            $(".toogle-3").prop('checked', false);
            $(".pro-quantity-3").val(0);
            ordered($(".pro-quantity-3"));
            $("#input_3_3").val("");
            $(".price-3").html("");
            price3=0;
               sumAll();
        });

        $(".toogle-4").click(function(){
           if($(this).is(':checked')){
             $(this).addClass("active");
             $(".checked-4").addClass("active");
               $(".pro-quantity-4").val(1);
             $(".pro-quantity-4").trigger("click");
           }
        });

        $(".checked-4").click(function(){
            $(this).removeClass("active");
            $(".toogle-4").removeClass("active");
            $(".toogle-4").prop('checked', false);
            $(".pro-quantity-4").val(0);
            ordered($(".pro-quantity-4"));
            $("#input_3_4").val("");
            $(".price-4").html("");
            price4=0;
               sumAll();
        });


        $(".toogle-5").click(function(){
           if($(this).is(':checked')){
             $(this).addClass("active");
             $(".checked-5").addClass("active");
               $(".pro-quantity-5").val(1);
             $(".pro-quantity-5").trigger("click");
           }
        });

        $(".checked-5").click(function(){
            $(this).removeClass("active");
            $(".toogle-5").removeClass("active");
            $(".toogle-5").prop('checked', false);
            $(".pro-quantity-5").val(0);
            ordered($(".pro-quantity-5"));
            $("#input_3_5").val("");
            $(".price-5").html("");
            price5=0;
               sumAll();
        });

        $(".toogle-6").click(function(){
           if($(this).is(':checked')){
             $(this).addClass("active");
             $(".checked-6").addClass("active");
               $(".pro-quantity-6").val(1);
             $(".pro-quantity-6").trigger("click");
           }
        });

        $(".checked-6").click(function(){
            $(this).removeClass("active");
            $(".toogle-6").removeClass("active");
            $(".toogle-6").prop('checked', false);
            $(".pro-quantity-6").val(0);
            ordered($(".pro-quantity-6"));
            $("#input_3_6").val("");
            $(".price-6").html("");
            price6=0;
               sumAll();
        });


        $(".toogle-7").click(function(){
           if($(this).is(':checked')){
             $(this).addClass("active");
             $(".checked-7").addClass("active");
               $(".pro-quantity-7").val(1);
             $(".pro-quantity-7").trigger("click");
           }
        });

        $(".checked-7").click(function(){
            $(this).removeClass("active");
            $(".toogle-7").removeClass("active");
            $(".toogle-7").prop('checked', false);
            $(".pro-quantity-7").val(0);
            ordered($(".pro-quantity-7"));
            $("#input_3_7").val("");
            $(".price-7").html("");
            price7=0;
               sumAll();
        });


        $(".toogle-8").click(function(){
           if($(this).is(':checked')){
             $(this).addClass("active");
             $(".checked-8").addClass("active");
               $(".pro-quantity-8").val(1);
             $(".pro-quantity-8").trigger("click");
           }
        });

        $(".checked-8").click(function(){
            $(this).removeClass("active");
            $(".toogle-8").removeClass("active");
            $(".toogle-8").prop('checked', false);
            $(".pro-quantity-8").val(0);
            ordered($(".pro-quantity-8"));
            $("#input_3_36").val("");
            $(".price-8").html("");
            price8=0;
               sumAll();
        });

        $(".toogle-9").click(function(){
           if($(this).is(':checked')){
             $(this).addClass("active");
             $(".checked-9").addClass("active");
               $(".pro-quantity-9").val(1);
             $(".pro-quantity-9").trigger("click");
           }
        });

        $(".checked-9").click(function(){
            $(this).removeClass("active");
            $(".toogle-9").removeClass("active");
            $(".toogle-9").prop('checked', false);
            $(".pro-quantity-9").val(0);
            ordered($(".pro-quantity-9"));
            $("#input_3_34").val("");
            $(".price-9").html("");
            price9=0;
               sumAll();
        });

        $(".toogle-10").click(function(){
           if($(this).is(':checked')){
             $(this).addClass("active");
             $(".checked-10").addClass("active");
              $(".pro-quantity-10").val(1);
             $(".pro-quantity-10").trigger("click");
           }
        });

        $(".checked-10").click(function(){
            $(this).removeClass("active");
            $(".toogle-10").removeClass("active");
            $(".toogle-10").prop('checked', false);
            $(".pro-quantity-10").val(0);
            ordered($(".pro-quantity-10"));
            $("#input_3_35").val("");
            $(".price-10").html("");
            price10=0;
               sumAll();
        });

        $(".btn_next.btn-next1").click(function(){
          if($(".step-1").hasClass("active")){
            $(".step-1").removeClass("active");
            $(".step-2").addClass("active");
            $(".btn_next.btn-next2").show();
            $(".btn_next.btn-next1").hide();
            $(".step-btn-2").addClass("active");
            $(".step-btn-1").removeClass("active");
            $(".step-btn-3").removeClass("active");
            $(".step-btn-4").removeClass("active");
              $("html, body").animate({ scrollTop: 0 }, "fast");
          }
        });


        $(".btn_next.btn-next2").click(function(){

          if(validate_data()){
                  if($(".step-2").hasClass("active")){
                    $(".step-2").removeClass("active");
                    $(".step-3").addClass("active");
                    $(".step-1").addClass("active");
                    $("body").addClass("step-3-page");
                    $("body").removeClass("step-1-page");
                    $(".btn_next.btn-next1").hide();
                      $(".btn_next.btn-next2").hide();
                    $(".btn_order").show();

                    $(".step-btn-3").addClass("active");
                    $(".step-btn-2").removeClass("active");
                    $(".step-btn-1").removeClass("active");
                    $(".step-btn-4").removeClass("active");

                    final_check_populate();
                    $("html, body").animate({ scrollTop: 0 }, "fast");
                  }
                }
        });

        $(".btn_back").click(function(){

            $(".step-1").addClass("active");
            $(".step-2").removeClass("active");
            $(".step-3").removeClass("active");
            $(".step-4").removeClass("active");
            $(".btn_next.btn-next1").show();
            $(".btn_next.btn-next2").hide();
            $(".btn_order").hide();
            $("body").addClass("step-1-page");
            $("body").removeClass("step-3-page");
              $("html, body").animate({ scrollTop: 0 }, "fast");
        });

        $(".btn_back.btn_form").click(function(){

            $(".step-1").removeClass("active");
            $(".step-2").addClass("active");
            $(".step-3").removeClass("active");
            $(".step-4").removeClass("active");
            $(".btn_next.btn-next1").hide();
            $(".btn_next.btn-next2").show();
            $(".btn_order").hide();
            $(".step-btn-2").addClass("active");
            $(".step-btn-1").removeClass("active");
            $(".step-btn-3").removeClass("active");
            $(".step-btn-4").removeClass("active");
            $("body").removeClass("step-3-page");
              $("html, body").animate({ scrollTop: 0 }, "fast");
        });

       $(".btn_order").click(function(){
         $(this).hide();
         $("#gform_3").submit();
         $(".step-4").addClass("active");
         $(".step-1").removeClass("active");
         $(".step-2").removeClass("active");
         $(".step-3").removeClass("active");
         $(".step-btn-4").addClass("active");
         $(".step-btn-2").removeClass("active");
         $(".step-btn-3").removeClass("active");
         $(".step-btn-1").removeClass("active");

           $("html, body").animate({ scrollTop: 0 }, "fast");
       });



      }
    }





  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
